<template>
  <div style="background: var(--v-component-base) !important">
    <v-card flat>
      <v-card-text class="my-0 py-0">
        <v-toolbar flat dense>
          <span class="mr-2">Selected Season: </span>
          <v-chip
            v-for="(year, index) in availableYears"
            :key="index"
            class="mx-1"
            label
            :color="
              params.selectedYear == year.value ? 'secondary' : 'grey darken-2'
            "
            @click="selectYear(year.value)"
            >{{ year.name }}</v-chip
          >
        </v-toolbar>

        <v-row>
          <v-col cols="12" sm="3">
            <v-card outlined flat style="border-radius: 20px" class="py-0 my-0">
              <v-card-text class="py-0 my-0">
                <v-list-item>
                  <v-list-item-action class="mx-0">
                    <v-icon color="green">{{
                      getStatusIcon("Arrived")
                    }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Arrived </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-icon small color="grey" class="mr-1">widgets</v-icon>
                      {{ filteredContainers("Arrived") }} containers
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-icon small color="grey" class="mr-1">list_alt</v-icon>
                      {{ filteredPallets("Arrived") }} Pallets
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card outlined flat style="border-radius: 20px" class="py-0 my-0">
              <v-card-text class="py-0 my-0">
                <v-list-item>
                  <v-list-item-action class="mx-0">
                    <v-icon color="blue">{{
                      getStatusIcon("In Transit")
                    }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> In Transit </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-icon small color="grey" class="mr-1">widgets</v-icon>
                      {{ filteredContainers("In Transit") }}
                      containers
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-icon small color="grey" class="mr-1">list_alt</v-icon>
                      {{ filteredPallets("In Transit") }} Pallets
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card outlined flat style="border-radius: 20px" class="py-0 my-0">
              <v-card-text class="py-0 my-0">
                <v-list-item>
                  <v-list-item-action class="mx-0">
                    <v-icon color="orange">{{
                      getStatusIcon("Awaiting Departure")
                    }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Awaiting Departure </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-icon small color="grey" class="mr-1">widgets</v-icon>
                      {{ filteredContainers("Awaiting Departure") }}
                      containers
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-icon small color="grey" class="mr-1">list_alt</v-icon>
                      {{ filteredPallets("Awaiting Departure") }}
                      Pallets
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              style="max-width: 400px"
              outlined
              dense
              rounded
              prefix-inner-icon="search"
              placeholder="Search"
              clearable
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mt-0 pt-0">
            <ag-grid-vue
      groupDisplayType="multipleColumns"
      :animateRows="true"
      :rowGroupPanelShow="'always'"
      :autoSizeStrategy="{
        type: 'fitCellContents',
        defaultMinWidth: 150,
        columnLimits: [{ colId: 'movementType', minWidth: 150 }],
      }"
      style="width: 100%; height: 76vh"
      :enableRangeSelection="true"
      :allowContextMenuWithControlKey="true"
      :columnDefs="columns"
      :rowData="shipments.data"
      :suppressAggFuncInHeader="true"
      :defaultColDef="defaultColDef"
      :sideBar="true"
      :statusBar="statusBar"
      :initialState="initialState"
      :getContextMenuItems="getContextMenuItems"
      :getRowId="getRowId"
      @state-updated="onStateUpdated"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn
          @click="infoStartGuide"
          icon
          style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0"
          ><v-icon color="primary" size="30">close</v-icon></v-btn
        >
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Shipment Overview!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>
              FRIKO <br />• Map out routes for transporters <br />• Add GeoJSON
              data to locations used determine if a vehicle is within a certain
              area <br />• Calculate accurate waiting times at loading points
              <br />• Calculate distances for billing purposes <br />• Pin-point
              locations for the purpose of reporting and analysis <br />• Map
              out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the
            button below to start the tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import dateFormat from "dateformat";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
// import steps from "./Steps/shipmentSteps";
import OrganisationName from "../GridComponents/Organisation.vue";
import PortCell from "../GridComponents/Port.vue";
import TableHeader from "../GridComponents/TableHeader.vue";
import * as moment from "moment";
export default {
  components: {AgGridVue,
    OrganisationName,
    PortCell,
    agColumnHeader: TableHeader,
  },
  props: ["selectedCustomer"],
  data: () => ({
    // guides
    steps: null,
    
    availableYears: [],
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: true,
      enablePivot: true,
      flex: 1,
      minWidth: 100,
      cellClass: "d-flex align-center",
      cellStyle: { textAlign: "center", fontSize: "11px" },
    },
    filters: {},
    filterKey: 2000,
    filteredResults: [],
    headerKey: 1000,
    // columns: [
    //   {
    //     headerName: "File Number",
    //     field: "fileNumber",
    //   },
    //   {
    //     headerName: "Cancelled",
    //     field: "cancelled",
    //   },
    //   {
    //     headerName: "Linked Bookings",
    //     field: "bookings",
    //   },
    //   {
    //     headerName: "No. Containers",
    //     field: "containers",
    //   },
    //   {
    //     headerName: "Vessel/Voyage",
    //     field: "vessel",
    //   },
    //   {
    //     headerName: "POL",
    //     field: "pol",
    //   },
    //   {
    //     headerName: "POD",
    //     field: "pod",
    //   },
    // ],
    columns: [],
    loadingConsigneeInstruction: false,
    loadingFiles: false,
    loadingProfiles: false,
    filePage: 1,
    fileParams: {
      limit: 12,
      offset: 0,
      search: null,
    },
    gridApi: null,
    initialState: {},
    loading: false,
    page: 1,
    params: {
      limit: 15,
      offset: 0,
      search: null,
    },
    profiles: [],
    shipments: {
      total: 0,
      data: null
    },
    shipment: {},
    shipmentDialog: false,
    shipmentFileDialog: false,
    shipmentFiles: {
      total: 0,
      data: [],
    },
    infoStart: false,
    search: null,
    searchTimeout: undefined,
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
  }),
  watch: {
    "selectedCustomer.relatedOrganisationId": {
      immediate: true,
      handler(val) {
        if (val) {
          // this.getCustomerShipments()
          this.getOverviewShipmentsByCustomer(this.selectedCustomer.id);
          // this.getConsigneeProfiles();
          //   this.getRelatedOrganisations();
        }
      },
    },
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
    // headerKey(){

    // }
    // async page(value) {
    //   this.params.offset = (value - 1) * this.params.limit;
    //   await this.getCustomerShipments();
    // },
    // "params.limit": {
    //   immediate: true,
    //   async handler() {
    //     await this.getCustomerShipments();
    //   },
    // },
    // filters(val){
    //   console.log(val)
    // }
  },
  computed: {
    filteredDetails() {
      let result = [...this.filteredResults];
      if (this.search) {
        result = result.filter(
          (x) =>
            x.references.some((y) =>
              y.toLowerCase().includes(this.search.toLowerCase())
            ) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            x.vessel.name.toLowerCase().includes(this.search.toLowerCase()) ||
            x.voyage.toLowerCase().includes(this.search.toLowerCase()) ||
            x.products.some((y) =>
              y.product.name.toLowerCase().includes(this.search.toLowerCase())
            )
        );
      }
      return result;
    },
  },
  async created() {
    await this.getSteps()
    if (
      localStorage.getItem(
        "contactShipmentTableState_" + this.$store.state.currentOrg.id
      )
    ) {
      let state = JSON.parse(
        localStorage.getItem(
          "contactShipmentTableState_" + this.$store.state.currentOrg.id
        )
      );
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }
    this.columns = [
      {
        headerName: "Status",
        field: "status",
        align: "center",
        filterable: true,
      },
      {
        headerName: "Arrival Week",
        field: "arrivalWeek",
        valueGetter: (params) => {
          return params && params.data && params.eta ? this.formatWeek(params.data.eta):null
        }
      },
      {
        headerName: "Carrier",
        field: "shippingLine.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shippingLine
            ? {
                organisation: p.data.shippingLine
              }
            : null;
        },
      },

      {
          headerName: "Shipper",
          field: "shipper.name",
          cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                organisation: p.data.shipper
              }
            : null;
        },
      },
      {
        headerName: "Receiver",
        field: "consignee.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                organisation: p.data.consignee
              }
            : null;
        },
      },
      // {
      //   headerName: "Vessel/Voyage",
      //   field: "vessel.name",
      //   sortable: false,
      //   align: "center",
      // },
      {
        headerName: "POL",
        field: "portOfLoadValue",
        sortable: false,
        align: "center",
        filterable: true,
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "ETD",
        field: "etd",
        sortable: false,
        align: "center",
        width: "120px",
      },
      {
        headerName: "ATD",
        field: "atd",
        sortable: false,
        align: "center",
        width: "120px",
      },
      {
        headerName: "POD",
        field: "portOfDischargeValue",
        sortable: false,
        align: "center",
        filterable: true,
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfDischargeValue : null,
            name: p.data ? p.data.portOfDischargeCity : null,
          };
        },
      },
      {
        headerName: "Final Dest.",
        field: "finalDestinationValue",
        sortable: false,
        align: "center",
        filterable: true,
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.finalDestinationValue : null,
            name: p.data ? p.data.finalDestinationCity : null,
          };
        },
      },

      {
        headerName: "ETA",
        field: "eta",
        width: "120px",
        sortable: false,
        align: "center",
      },
      {
        headerName: "ATA",
        field: "ata",
        width: "120px",
        sortable: false,
        align: "center",
      },

      {
        headerName: "Products",
        field: "products",
        align: "left",
        sortable: false,
      },
      {
        headerName: "References",
        field: "references",
        align: "left",
        sortable: false,
      },
      {
        headerName: "Containers",
        field: "noContainers",
        align: "center",
      },
      {
        headerName: "No. Pallets",
        field: "noPallets",
        align: "center",
      },
      {
        headerName: "Documents",
        field: "documents",
        align: "center",
      },
    ]
  },
  async mounted() {
    this.driver = new Driver({
      animate: false,
    });
    this.filters = {};
  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'Contacts/Shipment/Steps',
        });
        this.steps = steps.steps
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "View Shipment",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
                this.viewShipment(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    getRowId(params) {
      return params.data.id;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
    },
    onStateUpdated(state) {
      localStorage.setItem(
        "contactShipmentTableState_" + this.$store.state.currentOrg.id,
        JSON.stringify(state.state)
      );
    },
    calculateContainers(bookings) {
      return bookings
        .map((item) => item.bookingContainers.length)
        .reduce((prev, next) => prev + next);
    },
    async addShipment(item) {
      this.shipment = {
        shipmentFileId: item.id,
        consigneeProfileId: null,
      };
      this.createShipment();
      //   this.shipmentDialog = true;
    },
    async createShipment() {
      this.shipment.consigneeProfileId = this.shipment.consigneeProfile;
      let result = await this.$API.createShipment(this.shipment);
      this.shipmentDialog = false;
      this.$router.push({
        path: "/shipment/" + result.id,
      });
    },
    calculateFilteredResults() {
      let result = [...this.shipments.data];
      // console.log(result.length)
      // if (this.search) {
      //   result = result.filter(x =>
      //     x.references.some(y => y.toLowerCase().includes(this.search.toLowerCase())) ||
      //     (x.shipper && x.shipper.name.toLowerCase().includes(this.search.toLowerCase())) ||
      //     (x.consignee && x.consignee.name.toLowerCase().includes(this.search.toLowerCase())) ||
      //     x.vessel.name.toLowerCase().includes(this.search.toLowerCase()) ||
      //     x.voyage.toLowerCase().includes(this.search.toLowerCase()) ||
      //     x.products.some(y => y.product.name.toLowerCase().includes(this.search.toLowerCase()))
      //   )
      // }
      let keys = Object.keys(this.filters);
      // for(let i=0;i<keys.length;i++){
      //   if(this.filters[keys[i]].length > 0){
      //     result = result.filter(x => this.filters[keys[i]].includes(x[keys[i]]))
      //   }
      // }
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.filters[key] && this.filters[key].length > 0) {
          result = result.filter((x) => this.filters[key].includes(x[key]));
        }
      }
      // Object.keys(this.filters).forEach(key => {
      //   if(this.filters[key].length > 0){
      //     result = result.filter(x => this.filters[key].includes(x[key]))
      //   }
      // })
      this.filteredResults = result;
    },
    filteredContainers(status) {
      let data = this.filteredResults.filter((x) => x.status === status);
      return data.map((x) => x.noContainers).reduce((a, b) => a + b, 0);
    },
    filteredPallets(status) {
      let data = this.filteredResults.filter((x) => x.status === status);
      return data.map((x) => x.noPallets).reduce((a, b) => a + b, 0);
    },
    formatWeek(date) {
      return date
        ? moment(new Date(date)).isoWeek() +
            " - " +
            moment(new Date(date)).format("YYYY")
        : null;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd mmm, yyyy HH:MM");
      }
      return result;
    },
    getStatusColor(status) {
      switch (status) {
        case "Awaiting Departure":
          return "orange";
        case "In Transit":
          return "blue";
        case "Arrived":
          return "green";
        case "Cancelled":
          return "red";
        default:
          return "darkgrey";
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case "Awaiting Departure":
          return "location_on";
        case "In Transit":
          return "mode_of_travel";
        case "Arrived":
          return "where_to_vote";
        case "Cancelled":
          return "close";
        default:
          return "info";
      }
    },
    async getConsigneeProfiles() {
      (this.loadingProfiles = true),
        (this.profiles = await this.$API.listCustomerConsigneeProfile(
          this.selectedCustomer.relatedOrganisationId,
          {}
        ));
      this.loadingProfiles = false;
    },
    async getOverviewShipmentsByCustomer(id) {
      this.loading = true;
      this.shipments = await this.$API.getOverviewShipmentsByCustomer(id, {
        params: this.params,
      });
      this.availableYears = this.shipments.availableYears;
      if (!this.shipments.availableYears.includes(this.params.selectedYear)) {
        this.params.selectedYear = this.shipments.currentYear;
      }
      // setTimeout(() => {
      //   Object.keys(this.$refs.table.openCache).forEach(
      //     (g) => (this.$refs.table.openCache[g] = false)
      //   );
      // }, 500);
      // this.calculateFilteredResults();
      this.loading = false;
    },
    async getCustomerShipments() {
      this.loading = true;
      this.shipments = await this.$API.getCustomerShipments(
        this.selectedCustomer.relatedOrganisationId,
        {
          params: this.params,
        }
      );
      this.loading = false;
    },
    async getShipmentFiles() {
      this.loadingFiles = true;
      this.shipmentFiles = await this.$API.listShipmentFile({
        params: this.fileParams,
      });
      this.loadingFiles = false;
    },
    formatHeaderName(value, field, data) {
      switch (field) {
        case "vessel.name":
          return data.vessel.name;
        case "consignee.name":
          return data.consignee.name;
        default:
          return value;
      }
    },
   
    viewShipment(item) {
      this.$router.push({
        path: "/shipment/overview/" + item.hash,
        query: {
          status: item.status,
          vesselId: item.vesselId,
          vessel: item.vessel.name,
          voyage: item.voyage,
          vesselVoyageId: item.vesselVoyageId,
          shipper: item.shipper.name,
          shipperId: item.shipperId,
          consignee: item.consignee.name,
          consigneeId: item.consigneeId,
          pol: item.portOfLoadValue,
          pod: item.finalDestinationValue,
          arrivalWeek: item.arrivalWeek,
          etd: item.etd,
          eta: item.eta,
          noContainers: item.noContainers,
          noPallets: item.noPallets,
          noCartons: item.noCartons,
        },
      });
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    selectYear(year) {
      this.params.selectedYear = year;
      this.getOverviewShipmentsByCustomer(this.selectedCustomer.id);
    },
    startGuide(e) {
      this.infoStart = false;
      e.stopPropagation();
      this.driver.defineSteps(this.steps);
      this.driver.start();
    },
  },
};
</script>
  